body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.content {
    width:100%;

}

.content button {
    background: none;
    padding: 5px;
    border-radius: 2px;
}

.key {
    text-align: center;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 4px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-style: solid;
    border-width: 2px;
    border-color: #b7d7e5;
    padding-top: 8px;
}

.rows {
    display:flex;
}

.keyCharacter {
    font-weight: bold;
    top: 10%;
}


.SKey {
    background-color: #b4bbbe;
    align-content: center;
    margin-top: 0px;
}

button{
    font-family: SpaceMono, sans-serif;
    font-size:calc(8px + 0.5vw);
    text-decoration: none;
    border: solid;
    border-width: 2px;
    border-radius: 3px;
    margin: .5vh;
    padding:1vh;
    z-index: 50;
    color: #175b69;
    display: flex;
    align-items:center;
}

#practiceText{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start:1;
    grid-row-end:2;
    font-size: 50px;
    color: grey;
    left:0;
    right:0;
    top:20%;
    margin-bottom:10px;
    height: 100px;
    align-content: center;
    align-self: center;
    text-align: center;

}

#container{
    width: 100%;
}

#TabSK {
    width: 100px;
    aspect-ratio: 2/1;
    top: 50%
}

#BackspaceSK {
    width: 100px;
    aspect-ratio: 2/1;
}

#EnterSK {
    width: 100px;
    aspect-ratio: 2/1;
}

#CapsLockSK {
    width: 100px;
    aspect-ratio: 2/1;
}

#ShiftLeftSK {
    width: 125px;
    aspect-ratio: 2.5/1;
}

#ShiftRightSK {
    width: 125px;
    aspect-ratio: 2.5/1;
}


.keyboard {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-column-end: 3;
    border-width: 4px;
    border-style: solid;
    border-color: #175b69;
    background-color: #b7d7e5;
    border-radius: 6px;
    margin:auto;
    width:780px;
    left:0;
    padding-top:10px;
    padding-bottom:15px;
    right:0;
    z-index:-5;
    aspect-ratio: 4/1;
}

.keyboard .front {
    width:750px;

    margin: auto;
    position:absolute;
    z-index: 5;
    left:0;
    right:0;


}

@font-face {
    font-family: "Zhuyin";
    src: url("wp010-05.ttf");
}

#settings {

}

.char_char {
}

.char_zhuyin {
    font-size: medium;
    font-weight:bold;
    padding-top: 0px;
}

.char_guide {
    display:inline-block;
    padding-right: 20px;
}

