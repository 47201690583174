@font-face {
    font-family: SpaceMono;
    src: url('SpaceMono-Regular.ttf');
}



body{
    background-color: #eddbdb;

}

br {
    margin :0;
    padding: 0;
}

h3{
    color: darkslategray;
    font-family: SpaceMono, serif;
    text-align: center;
    text-decoration: none;
    display: block;
}

a:link, a:visited, a:hover, a:active h3{
    text-decoration: none;
}

h1 {
    font-family: SpaceMono, serif;
    color: #472C2C;
    text-align: center;
}


.navi {
    font-family: SpaceMono, sans-serif;
    font-size:calc(8px + 0.5vw);
    text-decoration: none;
    border: solid;
    border-width: 2px;
    border-radius: 3px;
    margin: .5vh;
    padding:1vh;
    float:right;
    z-index: 50;
    color: #175b69;
    display: flex;


}
.navi:visited {
    color: #175b69;
}
.navi:hover {
    background-color: #c4c7c0;
}

.header {
    display: flex;
    width: 100%;
    top: 0;
    border-width: 0;
    border-bottom-width: 3px;
    border-style: solid;
    z-index: 50;
    background: #b7d7e5;
    color: #175b69;
}

#links {
    width:100%;
    left:auto;
    right:0;
    margin-top: 0.5%;
    z-index: 50;

}

#name {
    margin-left: 5px;
    font-family: SpaceMono, sans-serif;
    font-size:calc(12px + 0.6vw);
    padding-left: 2px;
    text-decoration: none;
    width: 50%;
    display: flex;
    align-items:center;

}

#name:visited {
    color: black;
}

#face {
    width: 60%;
    margin: auto;

}
* {
    margin-top:0;
    font-family: "Lato", sans-serif;
}

.page {
    position: static;
    width:100%;

}

#home {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 1fr;
    width: 100%;
    font-family: SpaceMono, serif;

}

#profile {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row:1;
    display: grid;

    font-family: SpaceMono, serif;
    margin: auto;

}

#more {
    font-family: SpaceMono, sans-serif;
    font-size: 20px;
    width:100%;
    grid-column-start: 2;
    grid-column-end: 2;
    display:flex;
    align-self: center;
}

@media screen and (max-width: 480px) {

    .header {
        position: sticky;
        height: 3em;

    }

    * {
        margin: 0;
    }

    #home {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 10px 1fr;
        font-family: SpaceMono, serif;

    }

    #more {
        display: grid;
        grid-column: 1;
        grid-row: 3;
    }

    #profile {
        grid-row: 2;
        grid-column: 1;
        display: grid;
        grid-template-rows: 250px 1fr;
    }

    #face {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        grid-row: 1;
    }

    #profile h1{
        grid-row: 2;
        margin-left: auto;
        margin-right: auto;
    }

}